class InitPage {
    constructor() {
        this.pageLoaded = false;
        this.viewportScrolling = false;
    }

    setup() {
        (async() => {
            try {

                await global.resizing.resizeHandler();

            } catch (error) {
                console.error('(FROM ASYNC/AWAIT) Error cause is:', error);
            }
        })();

        $('body').addClass('page-loaded');
        this.pageLoaded = true;
    }
}

module.exports = { InitPage };
