class Utilities {
    constructor() {}

    updateURL(href) {
        if (history && history.pushState && href) {
            history.pushState(null, null, href);
        }
    }

    updateTitle(title) {
        if (title) {
            document.title = title;
        }
    }

    scrollToTop(t) {
        var nt = t;
        if (!nt || nt <= 0) { nt = 800; }
        $('html, body').animate({ scrollTop: 0 }, nt, "swing");
    }

    scrollTo(jQueryElement, offset = 0) {
        if (jQueryElement) {
            try {
                $('html, body').animate({ scrollTop: jQueryElement.offset().top - offset }, 800);
            } catch (e) {}
        }
    }

    //trigger something at a const max rate
    //const myHandler = (event) => // do something with the event
    //const tHandler = throttled(200, myHandler);
    //domNode.addEventListener("mousemove", tHandler);
    throttled(delay, fn) {
      let lastCall = 0;
      return function (...args) {
        const now = (new Date).getTime();
        if (now - lastCall < delay) {
          return;
        }
        lastCall = now;
        return fn(...args);
      }
    }

    //trigger something only once interaction is stopped, e.g. typing
    //const myHandler = (event) => // do something with the event
    //const dHandler = debounced(200, myHandler);
    //domNode.addEventListener("input", dHandler);
    debounced(delay, fn) {
      let timerId;
      return function (...args) {
        if (timerId) {
          clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
          fn(...args);
          timerId = null;
        }, delay);
      }
    }

    interval(func, wait, times){
        var interv = function(w, t){
            return function(){
                if(typeof t === "undefined" || t-- > 0){
                    setTimeout(interv, w);
                    try{
                        func.call(null);
                    }
                    catch(e){
                        t = 0;
                        throw e.toString();
                    }
                }
            };
        }(wait, times);

        setTimeout(interv, wait);
    };

    setupClearFocusForms() {
        $(".clearMeFocus").focus(function() {
            if ($(this).val() == $(this).attr("title")) {
                $(this).val("");
            }
        });
        $(".clearMeFocus").blur(function() {
            if ($(this).val() == "") {
                $(this).val($(this).attr("title"));
            }
        });
    }

    getMaxHeight(el, outerHeight = false, padding = false){
        var maxHeight = 0;
        if(outerHeight){
            if(padding){
                maxHeight = Math.max.apply(null, el.map(function(){return $(this).outerHeight(true);}).get());
            }else{
                maxHeight = Math.max.apply(null, el.map(function(){return $(this).outerHeight();}).get());
            }
        }else{
            maxHeight = Math.max.apply(null, el.map(function(){return $(this).height();}).get());
        }
        return maxHeight;
    }

    updateSizeRefs() {
        //Update size references
        if (typeof(window.innerWidth) === 'number') {
            //Non-IE
            global.viewportWidth = window.innerWidth;
            global.viewportHeight = window.innerHeight;
        } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
            //IE 6+ in 'standards compliant mode'
            global.viewportWidth = document.documentElement.clientWidth;
            global.viewportHeight = document.documentElement.clientHeight;
        } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
            //IE 4 compatible
            global.viewportWidth = document.body.clientWidth;
            global.viewportHeight = document.body.clientHeight;
        }
    }

    measureScrollBarWidth() {
        var scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        global.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
    }

}

module.exports = { Utilities };
